import { del, get, post, put } from "aws-amplify/api";
import {
  fetchAuthSession,
  fetchUserAttributes,
  getCurrentUser,
} from "aws-amplify/auth";
import toast from "react-hot-toast";

import { Amplify } from "aws-amplify";

export const configureAmplify = async (awsExports) => {
  const user = await getCurrentUser();
  const attributes = await fetchUserAttributes(user);
  const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
  const tokens = {
    access_token: accessToken.toString(),
    id_token: idToken.toString(),
  };
  Amplify.configure(awsExports, {
    API: {
      REST: {
        headers: async () => {
          return { Authorization: tokens.id_token };
        },
      },
    },
  });
  return { user, attributes, tokens };
};

export const updateStream = async (images, stream_id, stream_name) => {
  try {
    const stream = {
      stream_name: stream_name,
      stream_id: stream_id,
      images: images,
      user_id: "abcd",
    };
    const restOperation = put({
      apiName: "apiea3d53e9",
      path: "/streams/" + stream_id,
      options: {
        body: stream,
      },
    });
    const response = await restOperation.response;
    console.log("POST call succeeded: ", response);
  } catch (error) {
    console.log("GET call failed: ", error);
  }
};

export const createStream = async (images, stream_name = "My First Stream") => {
  try {
    const stream = {
      stream_name: stream_name,
      images: images,
      user_id: "abcd",
    };
    const restOperation = post({
      apiName: "apiea3d53e9",
      path: "/streams/create",
      options: {
        body: stream,
      },
    });
    const response = await restOperation.response;
    console.log("POST call succeeded: ", response);
  } catch (error) {
    console.log("GET call failed: ", error);
  }
};

export const deleteStream = async (streamId) => {
  try {
    const restOperation = del({
      apiName: "apiea3d53e9",
      path: "/streams/" + streamId,
      options: {
        queryStringParameters: {
          streamId: streamId,
        },
      },
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
};

export const getDefaultScreenSaver = async () => {
  try {
    const restOperation = get({
      apiName: "apiea3d53e9",
      path: "/getdefaultscreensaver",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
    return {};
  }
};

export const setMyScreenSaver = async (item, allDialogs, setScreensaver) => {
  if (
    item.screensaver_url === undefined ||
    item.screensaver_url === "" ||
    item.screensaver_url == null
  ) {
    allDialogs.showAlerts(
      null,
      "Screensaver creation in progress, Please wait till its generated",
      false
    );
    alert("Screensaver creation in progress, Please wait till its generated");
    return;
  }
  allDialogs.showAlerts(null, "", true);
  const screensaver_url = item.screensaver_url.includes("https")
    ? item.screensaver_url
    : `https://d1a8fzwrytzc3k.cloudfront.net/${item.screensaver_url}`;
  await setDefaultScreenSaver(item.stream_id, screensaver_url, allDialogs);
  setScreensaver(item);
  allDialogs.showAlerts(null, "Screensaver Set!", false);
};

export const editStream = async (item, setDefaultStream, setStreamVisible) => {
  console.log("Edit: ", item.stream_id);
  setDefaultStream(item);
  setStreamVisible(true);
};

export const seePreview = async (item, allDialogs, playVideo) => {
  if (
    item.screensaver_url === undefined ||
    item.screensaver_url === "" ||
    item.screensaver_url == null
  ) {
    allDialogs.showAlerts(
      null,
      "Screensaver creation in progress, Please wait till its generated",
      false
    );
    alert("Screensaver creation in progress, Please wait till its generated");
    return;
  }
  const screensaver_url = item.screensaver_url.includes("https")
    ? item.screensaver_url
    : `https://d1a8fzwrytzc3k.cloudfront.net/${item.screensaver_url}`;
  playVideo(screensaver_url);
};

export const showToast = (msg, type) => {
  const icon = type === "err" ? "🔴" : "🟢";
  toast(msg, {
    icon: icon,
    style: {
      borderRadius: "10px",
      background: "#333",
      color: "#fff",
    },
    duration: 5000
  });
};

export const setDefaultScreenSaver = async (id, url, allDialogs) => {
  let message = "Setting as default screensaver : Success";
  allDialogs.showAlerts(null, "", true);
  try {
    const restOperation = get({
      apiName: "apiea3d53e9",
      path: `/setscreensaver?screensaver_id=${id}&screensaver_url=${url}`,
    });
    await restOperation.response;
  } catch (error) {
    message = "Setting as default screensaver : Failed";
  }
  allDialogs.showAlerts(null, "", false);
};

export const activateTv = async (code) => {
  try {
    const restOperation = get({
      apiName: "apiea3d53e9",
      path: "/activatetv",
      options: {
        queryStringParameters: {
          code: code,
        },
      },
    });
    await restOperation.response;
  } catch (error) {
    console.log("GET call failed: ", error);
  }
};

export const getAllStreams = async () => {
  try {
    const restOperation = get({
      apiName: "apiea3d53e9",
      path: "/streams/getall",
    });
    const response = await restOperation.response;
    return response.body.json();
  } catch (error) {
    console.log("GET call failed: ", error);
  }
};
