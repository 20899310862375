// ActivateTVDialog.js
import { get } from "aws-amplify/api";
import React, { useState } from "react";
import { Modal, Text, TextInput, TouchableOpacity, View } from "react-native";
import styles from "../styles/ActivateTvStyles";

const ActivateTVDialog = ({ isVisible, onClose, setIsLoading, allDialogs }) => {
  const [code, setCode] = useState("");

  const fetchData = async () => {
    allDialogs.showAlerts("", "", true);
    let message = "TV activated successfully";
    try {
      const restOperation = get({
        apiName: "apiea3d53e9",
        path: "/activatetv?code=" + code,
      });
      await restOperation.response;
    } catch (error) {
      message = "Failed to activate TV - Invalid code";
    }
    setCode("");
    onClose();
    allDialogs.showAlerts("", message, false);
  };

  return (
    <Modal
      visible={isVisible}
      transparent={true}
      animationType="fade"
      onRequestClose={onClose}
    >
      <View style={styles.modalOverlay}>
        <View style={styles.dialog}>
          <div className="modal-close">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => onClose()}
            ></button>
          </div>
          <Text style={styles.dialogHeading}>Activate TV</Text>
          <Text style={styles.label}>Enter code</Text>
          <TextInput
            style={styles.input}
            value={code}
            placeholder="Enter the activation code displayed on your TV"
            onChangeText={setCode}
          />
          <TouchableOpacity style={styles.button} onPress={() => fetchData()}>
            <Text style={styles.buttonText}>Activate</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  );
};

export default ActivateTVDialog;
