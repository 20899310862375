import { updatePassword } from "aws-amplify/auth";
import React, { useState } from "react";
import {
  Alert,
  Modal,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import styles from "../styles/DialogStyles";

const UpdatePasswordComponent = ({ visible, onClose }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const validateInputs = () => {
    // Check if any input is empty
    if (!currentPassword || !newPassword || !confirmPassword) {
      console.log("All fields are required.");
      return false;
    }

    // Check if inputs have a minimum length of 8
    if (
      currentPassword.length < 8 ||
      newPassword.length < 8 ||
      confirmPassword.length < 8
    ) {
      Alert.alert("Error", "Passwords must be at least 8 characters long.");
      console.log("Passwords must be at least 8 characters long.");
      return false;
    }

    // Check if new password and confirm password match
    if (newPassword !== confirmPassword) {
      Alert.alert(
        "Error",
        "New password and confirmation password do not match."
      );
      console.log("New password and confirmation password do not match.");
      return false;
    }

    return true;
  };

  const validePasswords = () => {
    console.log("validating ");
    if (validateInputs()) {
      // If all validations pass, call changePassword function
      changePassword(currentPassword, newPassword);
    }
  };

  const changePassword = async (oldPassword, newPassword) => {
    try {
      await updatePassword({ oldPassword, newPassword });
      console.log("Password changed successfully");
      // Handle password change success (e.g., show a success message)
    } catch (error) {
      console.error("Error changing password:", error);
      // Handle errors (e.g., incorrect current password, weak new password, etc.)
    }
  };

  return (
    <Modal
      visible={visible}
      transparent={true}
      animationType="fade"
      onRequestClose={onclose}
    >
      <View style={styles.modalOverlay}>
        <View style={styles.dialog}>
          <div className="modal-close">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => onClose()}
            ></button>
          </div>
          <Text style={styles.dialogHeading}>Update Password</Text>
          <View style={styles.inputGroup}>
            <Text style={styles.label}>Current Password</Text>
            <TextInput
              style={styles.input}
              placeholder="Enter current password"
              secureTextEntry
              value={currentPassword}
              onChangeText={setCurrentPassword}
            />
          </View>
          <View style={styles.inputGroup}>
            <Text style={styles.label}>New Password</Text>
            <TextInput
              style={styles.input}
              placeholder="Enter new password"
              secureTextEntry
              value={newPassword}
              onChangeText={setNewPassword}
            />
          </View>
          <View style={styles.inputGroup}>
            <Text style={styles.label}>Re-enter New Password</Text>
            <TextInput
              style={styles.input}
              placeholder="Confirm new password"
              secureTextEntry
              value={confirmPassword}
              onChangeText={setConfirmPassword}
            />
          </View>
          <View style={styles.buttonRow}>
            <TouchableOpacity onPress={onClose} style={styles.button}>
              <Text style={styles.buttonText}>Cancel</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={validePasswords} style={styles.button}>
              <Text style={styles.buttonText}>Update</Text>
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default UpdatePasswordComponent;
