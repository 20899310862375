import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";

function CarouselView(props) {
  const images = props.images;

  const galleryImages = images.map((item, index) => ( { original: item, thumbnail: item } ));

  return (
    <div>
       <div className="player-close">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => props.setViewCarousel([])}
        >
          <i className="fa fa-times-circle" aria-hidden="true"></i>
        </button>
      </div>
      <ImageGallery items={galleryImages}/>
    </div>
  );
}

export default CarouselView;
