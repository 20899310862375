import { Image } from "@aws-amplify/ui-react";

function Header({
  username,
  handleSignOut,
  handleActivateTV,
  handleMyAccount,
}) {
  return (
    <div className="header">
      <div className="col-auto">
        <h6 className="mb-0">
          <Image src="assets/header-logo.svg" alt="" />{" "}
        </h6>
      </div>
      <div className="col-auto header-right">
        {/* <input
          className="form-control search-input fuzzy-search"
          type="search"
          placeholder="Search..."
          aria-label="Search"
        /> */}
        <div className="user-sec">
          <span className="user">{username}</span>

          <div className="btn-group">
            <button
              type="button"
              className="btn dropdown-toggle"
              data-bs-toggle="dropdown"
              data-bs-display="static"
              aria-expanded="false"
            >
              <Image src="assets/option-dots.svg" alt="" />
            </button>
            <ul className="dropdown-menu dropdown-menu-lg-end">
              <li>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={handleMyAccount}
                >
                  <i className="fa fa-user-circle" aria-hidden="true"></i>My
                  Account
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={handleActivateTV}
                >
                  <i className="fa fa-television" aria-hidden="true"></i>
                  Activate TV
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={handleSignOut}
                >
                  <i className="fa fa-sign-out" aria-hidden="true"></i>Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Header;
