import { Authenticator, Image } from "@aws-amplify/ui-react";

export const LoginScreen = () => {
  return (
    <div className="loginscreen">
      <div className="loginbg">
        <div className="logo">
          <Image src="assets/pic-flow-logo.svg" alt="" />
        </div>
        <Authenticator />
      </div>
    </div>
  );
};
