import React from "react";
import Slider from "react-slick";

import PropTypes from "prop-types";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

function CarouselSlider(props) {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          // initialSlide: 2
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  const images = props.images;

  return (
    <Slider {...settings}>
      {images.map((item, index) => (
        <div key={index} className="img-container">
          <img src={item} alt="placeholder" onClick={() => props.setViewCarousel(images)}/>
        </div>
      ))}
    </Slider>
  );
}

CarouselSlider.propTypes = {
  images: PropTypes.array.isRequired,
};

export default CarouselSlider;
