import { Loader } from "@aws-amplify/ui-react";
import React from "react";
import CarouselSlider from "./CarouselSlider";

import { deleteStream, editStream, seePreview, setMyScreenSaver } from "../Common.js";

function CarouselList({
  items,
  playVideo,
  allDialogs,
  setDefaultStream,
  setStreamVisible,
  setScreensaver,
  screensaver,
  setStreams,
  setViewCarousel,
}) {

  const deleteOnServer = async (stream_id, streams) => {
    allDialogs.showAlerts(null, "Deleting Screensaver...", true);
    await deleteStream(stream_id);
    // remove stream_id from streams
    const newStreams = streams.filter(
      (stream) => stream.stream_id !== stream_id
    );
    setStreams(newStreams);
    if (screensaver?.stream_id === stream_id) {
      setScreensaver("");
    }
    allDialogs.showAlerts(null, "Screensaver Deleted!", false);
  };

  const streams = items;
  return (
    <div className="card">
      {streams.map((item, index) => (
        <div className="stream-content" key={item.stream_name + index}>
          <div className="card-content">
            <div className="titlebox">
              <h4>{item.stream_name}</h4>
              <p>{item.images.length} Images</p>
            </div>
            {item.screensaver_url === undefined ||
            item.screensaver_url === "" ||
            item.screensaver_url == null ? (
              <Loader variation="linear" />
            ) : (
              <></>
            )}
            <div className="btn-group">
              <button
                type="button"
                className="btn"
                onClick={() => setMyScreenSaver(item, allDialogs, setScreensaver)}
              >
                Set as Screensaver
              </button>
              <button
                type="button"
                className="btn dropdown-toggle"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                aria-expanded="false"
              >
                {/* <Image src="assets/more-dots.svg" alt="" /> */}
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
              </button>
              <ul className="dropdown-menu dropdown-menu-lg-end">
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => seePreview(item, allDialogs, playVideo)}
                  >
                    <i className="fa fa-eye" aria-hidden="true"></i> Preview
                  </button>
                </li>
                { item.user_id !== 'public' ? 
                  (<li>
                    <button
                      className="dropdown-item"
                      type="button"
                      onClick={() => editStream(item, setDefaultStream, setStreamVisible)}
                    >
                      <i className="fa fa-pencil" aria-hidden="true"></i> Edit
                    </button>
                  </li>) : (<></>) }
                  {
                    item.user_id !== 'public' ? 
                    (<li>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={() => deleteOnServer(item.stream_id, streams)}
                      >
                        <i className="fa fa-trash" aria-hidden="true"></i> Delete
                      </button>
                    </li>) : (<></>)
                  }
              </ul>
            </div>
          </div>
          <div className="carousel-container">
            <CarouselSlider images={item.images} setViewCarousel={setViewCarousel}/>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CarouselList;
