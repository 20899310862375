/* eslint-disable */
import Uppy from "@uppy/core";
import GoogleDrive from "@uppy/google-drive";
import { Dashboard } from "@uppy/react";
import RemoteSources from "@uppy/remote-sources";
import Webcam from "@uppy/webcam";
import React from "react";
import { Modal, Text, TextInput, View } from "react-native";

import { uploadData } from "aws-amplify/storage";
import { v4 as uuidv4 } from "uuid";

import { createStream, getAllStreams, updateStream } from "../Common";

import styles from "../styles/ImageUploadStyles";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/file-input/dist/style.css";
import "@uppy/image-editor/dist/style.min.css";
import "@uppy/progress-bar/dist/style.css";

const imagesBasePath = "https://d1a8fzwrytzc3k.cloudfront.net/public/";

export default class MultiImageUpload extends React.Component {
  constructor(props) {
    super(props);

    const { user, allDialogs, setStreams, recheckScreensaver } = this.props;

    this.state = {
      showInlineDashboard: true,
      streamName: "",
      open: false,
      isEdit: false,
      dontUpdate: false,
      user: user,
    };

    this.uppy = new Uppy({ id: "uppy1", autoProceed: false, debug: true })
      .use(Webcam)
      .use(GoogleDrive, { companionUrl: "https://companion.tv2zprod.com/" })
      .use(RemoteSources, {
        companionUrl: "https://companion.tv2zprod.com/",
        sources: [
          "Dropbox",
          "Facebook",
          "Instagram",
          "OneDrive",
        ],
      });
    this.uppy.setOptions({
      restrictions: { maxNumberOfFiles: 30, allowedFileTypes: ["image/*"] },
    });

    this.uppy.on("complete", async (result) => {
      const { streamName, isEdit, stream_id } = this.state;
      if (streamName === "") {
        alert("Please enter the stream name");
        return;
      }
      this.closeDialog();
      allDialogs.showAlerts(null, null, true);
      const uploaded = await this.uploadUsingStorage(result.successful);
      if (uploaded.length > 0) {
        const response = isEdit
          ? await updateStream(uploaded, stream_id, streamName)
          : await createStream(uploaded, streamName);
        console.log(response);
      }
      const streams = await getAllStreams();
      setStreams(streams);
      recheckScreensaver();
      allDialogs.showAlerts(
        null,
        "Screensaver creation initiated successfully",
        false
      );
    });

    this.handleModalClick = this.handleModalClick.bind(this);
    this.preloadImages = this.preloadImages.bind(this);
    this.setCode = this.setCode.bind(this);
  }

  resetImages() {
    this.uppy.getFiles().forEach((file) => this.uppy.removeFile(file.id));
  }

  setStreamName(name) {
    this.setState((prevState) => ({ ...prevState, streamName: name }));
  }

  async uploadUsingStorage(images) {
    const uploadedImageUrls = {};
    // create an object with key as image name and '' as value for all images
    images.forEach((image) => {
      uploadedImageUrls[image.name] = "";
    });
    const { user } = this.state;
    const { username } = user;
    // upload images to storage and get the urls,
    // then create a stream with the urls and stream name
    // the images have to be uploaded asynchronously, we have to wait till all the images are uploaded and then create the stream
    await Promise.all(
      images.map(async (image) => {
        const imgName =
          username + "/" + uuidv4().replace(/-/g, "") + "." + image.extension;
        try {
          const result = await uploadData({
            key: imgName,
            data: image.data,
            options: {
              contentType: image.data.type,
              accessLevel: "guest",
            },
          }).result;
          uploadedImageUrls[image.name] = `${imagesBasePath}${imgName}`;
        } catch (error) {
          console.log("Error : ", error);
        }
      })
    );
    const orderedUrls = images.map((image) => uploadedImageUrls[image.name]);
    return orderedUrls;
  }

  preloadImages() {
    const { dontUpdate } = this.state;
    if (dontUpdate) return;
    const { defaultStream } = this.props;
    const { images } = defaultStream;
    this.resetImages();

    images.forEach((image) => {
      // extract the image name from the url
      const imageName = image.split("/").pop();
      fetch(image)
        .then((response) => response.blob())
        .then((blob) => {
          try {
            this.uppy.addFile({
              name: imageName,
              type: blob.type,
              data: blob,
            });
          } catch (error) {
            console.log(error);
          }
        });
    });
  }

  componentWillUnmount() {
    this.uppy.close({ reason: "unmount" });
  }

  handleModalClick() {
    this.setState({
      open: !this.state.open,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    this.preloadImages();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // FIXME: Fix this Convoluted logic
    const { streamName } = prevState;
    if (
      (streamName === "" || streamName === undefined) &&
      nextProps.defaultStream?.stream_name &&
      nextProps.defaultStream?.stream_name !== ""
    ) {
      return {
        ...prevState,
        streamName: nextProps.defaultStream.stream_name,
        stream_id: nextProps.defaultStream.stream_id,
        isEdit: true,
      };
    }
    return nextProps;
  }

  closeDialog() {
    this.setCode("");
    this.resetImages();
    this.props.onClose();
    this.setState({ dontUpdate: false, stream_id: "" });
  }

  setCode(msg) {
    this.setState((prevState) => ({
      ...prevState,
      streamName: msg,
      dontUpdate: true,
    }));
  }

  render() {
    return (
      <Modal
        visible={this.props.isVisible}
        transparent={true}
        animationType="fade"
        onRequestClose={() => this.closeDialog()}
      >
        <View style={styles.modalOverlay}>
          <View style={styles.dialog}>
            <div className="modal-close">
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => this.closeDialog()}
              ></button>
            </div>
            <Text style={styles.dialogHeading}>Upload New Images</Text>
            <TextInput
              style={styles.input}
              value={this.state.streamName}
              placeholder="Enter Screensaver Name"
              onChangeText={this.setCode}
            />
            <div id="uppyInline">
              <Dashboard
                uppy={this.uppy}
                plugins={[]}
                metaFields={[
                  { id: "name", name: "Name", placeholder: "File name" },
                ]}
              />
            </div>
          </View>
        </View>
      </Modal>
    );
  }
}
