import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import React, { useEffect, useState } from "react";
import { Modal, Text, TouchableOpacity, View } from "react-native";
import styles from "../styles/DialogStyles"; // Assuming you have this
import UpdatePasswordDialog from "./UpdatePasswordComponent";

const ProfileDialog = ({ isVisible, onClose }) => {
  const [updatePasswordVisible, setUpdatePasswordVisible] = useState(false);

  const [data, setData] = useState("");
  const [user, setUser] = useState("");

  useEffect(() => {
    // Define the async function
    const fetchData = async () => {
      try {
        const user = await getCurrentUser();
        const attributes = await fetchUserAttributes(user);

        setData(attributes);
        setUser(user);
      } catch (error) {
        console.error("Error fetching data:", error);
        setData("Failed to fetch data");
      }
    };

    // Invoke the async function
    fetchData();
  }, []); // The empty array ensures this effect runs only once after the initial render

  return (
    <Modal
      visible={isVisible}
      transparent={true}
      animationType="fade"
      onRequestClose={onClose}
    >
      <View style={styles.modalOverlay}>
        <View style={styles.dialog}>
          <div className="modal-close">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => onClose()}
            ></button>
          </div>
          <Text style={styles.dialogHeading}>My Account</Text>
          <View style={styles.row}>
            <Text style={styles.rowLabel}>Name:</Text>
            <Text style={styles.rowText}>{user.username}</Text>
          </View>
          <View style={styles.line} />

          <View style={styles.row}>
            <Text style={styles.rowLabel}>Email:</Text>
            <Text style={styles.rowText}>{data.email}</Text>
          </View>
          <View style={styles.line} />

          <View style={styles.row}>
            <Text style={styles.rowLabel}>Password:</Text>
            <Text style={styles.rowText}>********</Text>
          </View>

          <View style={styles.bottomTextContainer}>
            <TouchableOpacity onPress={() => setUpdatePasswordVisible(true)}>
              <Text style={styles.clickableText}>Update Password</Text>
            </TouchableOpacity>
            <UpdatePasswordDialog
              visible={updatePasswordVisible}
              onClose={() => setUpdatePasswordVisible(false)}
            />
          </View>
        </View>
      </View>
    </Modal>
  );
};

export default ProfileDialog;
