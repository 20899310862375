/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apiea3d53e9",
            "endpoint": "https://1f52ibi5e3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:efa849a8-11a4-4178-9a03-af81414dae06",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OV9iC3zcU",
    "aws_user_pools_web_client_id": "3f9sob2omaekppg523g73cbmi2",
    "oauth": {
        "domain": "picflow-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/auth/signin/",
        "redirectSignOut": "http://localhost:3000/auth/signout/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "picflowstreams-dev",
            "region": "us-east-1"
        }
    ],
    "aws_user_files_s3_bucket": "picflowefdd31e5128e4cfe893655a3a82090bc163431-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
