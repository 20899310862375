import { StyleSheet } from "react-native";

export default StyleSheet.create({
  modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  dialog: {
    width: 612,
    height: 396,
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: 'Outfit',
  },
  dialogHeading: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 5,
  },
  rowLabel: {
    color: '#999',
  },
  rowText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  line: {
    height: 1,
    backgroundColor: '#ddd',
    width: '100%',
    marginBottom: 5,
  },
  bottomTextContainer: {
    alignSelf: 'flex-end', // Aligns the clickable text to the right
    marginTop: 10,
  },
  clickableText: {
    color: '#37A0EA',
    fontSize: 16,
    fontWeight: 'bold',
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    paddingHorizontal: 10,
    borderRadius: 5,
    backgroundColor: '#FFF',
  },
  buttonRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%', // Ensure buttons align to the right
  },
  button: {
    marginLeft: 10,
    backgroundColor: '#007bff',
    padding: 10,
    borderRadius: 5,
  },
  buttonText: {
    color: '#ffffff',
  },
  inputGroup: {
    alignSelf: 'stretch',
    marginBottom: 10,
  },
  label: {
    fontSize: 16,
    marginBottom: 5,
    marginLeft: 3,
  },
});
