import { Authenticator, Loader } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { signOut } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import React, { useEffect, useState } from "react";
import "./App.css";
import awsExports from "./aws-exports";
import CarouselList from "./components/CarouselList.js";
import { MenuItems } from "./components/MenuItems.js";
import styles from "./styles/AppStyles";

import {
  configureAmplify,
  getAllStreams,
  getDefaultScreenSaver,
  showToast,
} from "./Common.js";
import { AlertMessage } from "./components/AlertMessage.js";
import CarouselView from "./components/CarouselView.js";
import Header from "./components/Header.js";
import { LoginScreen } from "./components/LoginScreen.js";
import { PreviewStream } from "./components/PreviewStream.js";
import { Screensaver } from "./components/Screensaver.js";

Amplify.configure(awsExports);

function App() {
  const [dialogVisible, setDialogVisible] = useState(false);
  const [activateTVVisible, setActivateTVVisible] = useState(false);
  const [streamVisible, setStreamVisible] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [showError, setShowError] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [viewCarousel, setViewCarousel] = useState([]);
  const [defaultStream, setDefaultStream] = useState({
    stream_name: "",
    images: [],
  });
  const [screensaver, setScreensaver] = useState("");
  const [streamUrl, setStreamUrl] = useState(
    "https://cdn.theoplayer.com/video/big_buck_bunny/big_buck_bunny.m3u8"
  );

  const playVideo = (url) => {
    setStreamUrl(url);
    setShowVideo(true);
  };

  const setMyScreenSaver = async (item) => {
    setScreensaver(item);
    // remove the item from allStreams
    const updatedStreams = state.allStreams.filter(
      (stream) => stream.stream_id !== item.stream_id
    );
    setState({...state, streams: updatedStreams});
  };

  const showAlerts = (error, message, loader) => {
    if (error) {
      setError(error);
      setShowError(true);
      showToast(error, "err");      
    }
    if (message) {
      setMessage(message);
      setShowMessage(true);
      showToast(message, "msg");      
    }
    setIsLoading(loader);
  };

  const hideAlerts = () => {
    setError("");
    setShowError(false);
    setMessage("");
    setShowMessage(false);
    setIsLoading(false);
  };

  const allDialogs = { showAlerts, hideAlerts };

  const [state, setState] = useState({
    isLoggedIn: false,
    user: null,
    attributes: null,
    tokens: null,
  });

  const setStreams = (streams) => {
    // remove the screensaver stream from the streams and set the new streams
    const updatedStreams = streams.filter(
      (stream) => stream.stream_id !== screensaver?.stream_id
    );
    setState({ ...state, streams: updatedStreams});
  };

  const checkLoggedIn = async () => {
    showAlerts(null, null, true);
    try {
      const { user, attributes, tokens } = await configureAmplify(awsExports);
      const streams = await getAllStreams();
      const defaultScreensaver = await getDefaultScreenSaver();
      if (defaultScreensaver.screen_saver_id !== undefined) {
        const screensaverSet = streams.find(
          (stream) => stream.stream_id === defaultScreensaver.screen_saver_id
        );
        setScreensaver(screensaverSet);
        // remove the screensaver from the streams list
      }
      const updatedStreams = streams.filter(
        (stream) => stream.stream_id !== defaultScreensaver?.screen_saver_id
      );
      setState({
        isLoggedIn: true,
        user,
        attributes,
        tokens,
        streams: updatedStreams,
        allStreams: streams,
      });
      setLoggedIn(true);
    } catch (err) {
      console.log(err);
    }
    showAlerts(null, null, false);
  };

  const recheckScreensaver = async() => {
    //Implementing the setInterval method
    const interval = setInterval(async() => {
      const streams = await getAllStreams();
      // filter streams that dont have the screensaver_url set
      const noScreensavers = streams.filter(
        (stream) => (stream.screensaver_url === "" || stream.screensaver_url === undefined || stream.screensaver_url === null)
      );
      if(noScreensavers.length === 0) {
        // remove the set interval
        clearInterval(interval);
        setStreams(streams);
      }
    }, 30000);
  }

  useEffect(() => {
    const listener = (data) => {
      const { event } = data?.payload ?? ""; // Add nullish coalescing operator
      if (event === "signedIn") checkLoggedIn();
    };
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setShowVideo(false);
      }
    };
    document.addEventListener("keydown", keyDownHandler);
    Hub.listen("auth", listener);
    checkLoggedIn();
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSignOut = async () => {
    const { isLoggedIn } = state;
    setIsLoading(true);
    setStreams([]);
    setScreensaver("");
    await signOut();
    setIsLoading(false);
  };
  const handleActivateTV = () => setActivateTVVisible(true);
  const handleMyAccount = () => setDialogVisible(true);

  if (isLoading)
    return (
      <div className="page-loader-container">
        <Loader width="7rem" height="7rem" />
      </div>
    );

  if (showVideo)
    return (
      <div className="preview-stream-container">
        <PreviewStream streamUrl={streamUrl} setShowVideo={setShowVideo} />
      </div>
    );

  if (viewCarousel.length > 0)
      return (
        <div className="preview-stream-container">
          <CarouselView images={viewCarousel} setViewCarousel={setViewCarousel}/>
        </div>
    )

  if (!state.isLoggedIn || !loggedIn) { 
    return <LoginScreen />;
  }

  return (
    <Authenticator>
      {({ signOut, user }) => (
        <main>
          {/* <LoadingScreen isVisible={isLoading} onClose={() => setToaster(false)} /> */}
          <Header
            username={state?.attributes?.email ?? user.username}
            handleActivateTV={handleActivateTV}
            handleSignOut={() => {
              setLoggedIn(false);
              setState({ ...state, isLoggedIn: false });
              handleSignOut() }}
            handleMyAccount={handleMyAccount}
          />
          <div className="main-container">
          <AlertMessage
            showError={showError}
            showMessage={showMessage}
            error={error}
            message={message}
          />
            <div className="page-title">
              <h2>My Library</h2>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setDefaultStream({ stream_name: "", images: [] });
                  setStreamVisible(true);
                }}
              >
                + Create New Screensaver
              </button>
            </div>
            <Screensaver
              stream={screensaver}
              playVideo={playVideo}
              allDialogs={allDialogs}
              setStreamVisible={setStreamVisible}
              setDefaultStream={setDefaultStream}
              setViewCarousel={setViewCarousel}
            />
            <CarouselList
              items={state.streams ?? []}
              playVideo={playVideo}
              allDialogs={allDialogs}
              setDefaultStream={setDefaultStream}
              setStreamVisible={setStreamVisible}
              setShowVideo={setShowVideo}
              setStreams={setStreams}
              setScreensaver={setMyScreenSaver}
              screensaver={screensaver}
              setViewCarousel={setViewCarousel}
            />
            <MenuItems
              styles={styles}
              {...{
                dialogVisible,
                setDialogVisible,
                activateTVVisible,
                setIsLoading,
                allDialogs,
                setActivateTVVisible,
                streamVisible,
                setStreamVisible,
                defaultStream,
                setDefaultStream,
                setStreams,
                user,
                recheckScreensaver,
              }}
            />
          </div>
        </main>
      )}
    </Authenticator>
  );
}

export default App;
