import ReactPlayer from "react-player";

export const PreviewStream = ({ streamUrl, setShowVideo }) => {
  const onStart = () => {
    console.log("Playing");
  };
  const onEnded = () => {
    console.log("Ended");
    setShowVideo(false);
  };

  return (
    <div width="100vw" height="100vh">
      <div className="player-close">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => setShowVideo(false)}
        >
          <i className="fa fa-times-circle" aria-hidden="true"></i>
        </button>
      </div>
      <ReactPlayer
        url={streamUrl}
        onStart={onStart}
        onEnded={onEnded}
        width="100vw"
        height="100vh"
        playing
      />
    </div>
  );
};
