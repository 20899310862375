import React from "react";
import { View } from "react-native";
import ActivateTVDialog from "./ActivateTVDialog";
import MultiImageUpload from "./MultiImageUpload";
import ProfileDialog from "./ProfileDialog";

export const MenuItems = ({
  styles,
  dialogVisible,
  setDialogVisible,
  activateTVVisible,
  setIsLoading,
  allDialogs,
  setActivateTVVisible,
  streamVisible,
  setStreamVisible,
  defaultStream,
  setDefaultStream,
  setStreams,
  recheckScreensaver,
  user,
}) => {
  return (
    <View style={styles.container}>
      <ProfileDialog
        isVisible={dialogVisible}
        onClose={() => setDialogVisible(false)}
      />

      <ActivateTVDialog
        isVisible={activateTVVisible}
        setIsLoading={setIsLoading}
        allDialogs={allDialogs}
        onClose={() => setActivateTVVisible(false)}
      />
      <MultiImageUpload
        isVisible={streamVisible}
        defaultStream={defaultStream}
        setDefaultStream={setDefaultStream}
        user={user}
        allDialogs={allDialogs}
        setStreams={setStreams}
        recheckScreensaver={recheckScreensaver}
        onClose={() => {
          setStreamVisible(false);
          setDefaultStream({ stream_name: "", images: [] });
        }}
      />
    </View>
  );
};
