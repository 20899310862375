import { Alert } from "@aws-amplify/ui-react";

export const AlertMessage = ({ showError, showMessage, error, message }) => {
  return (
    <>
      {showError && (
        <Alert isDismissible={true} hasIcon={true} variation="error">
          {error}
        </Alert>
      )}
      {showMessage && (
        <Alert isDismissible={true} hasIcon={true} variation="info">
          {message}
        </Alert>
      )}
    </>
  );
};
