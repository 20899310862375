import CarouselSlider from "./CarouselSlider";

import { editStream, seePreview } from "../Common";

export const Screensaver = ({ stream, allDialogs, playVideo, setDefaultStream, setStreamVisible, setViewCarousel}) => {
  if (stream && stream.images) {
    return (
      <div className="card">
        <div className="card-title">Current Screensaver</div>
        <div className="stream-content current">
          <div className="card-content">
            <div className="titlebox">
              <h4>{stream.stream_name}</h4>
              <p>{stream.images.length} Images</p>
            </div>
            <div className="btn-group">
              <button
                type="button"
                className="btn dropdown-toggle"
                data-bs-toggle="dropdown"
                data-bs-display="static"
                aria-expanded="false"
              >
                <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                {/* <Image src="assets/more-dots.svg" alt="" /> */}
              </button>
              <ul className="dropdown-menu dropdown-menu-lg-end">
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => seePreview(stream, allDialogs, playVideo)}
                  >
                    <i className="fa fa-eye" aria-hidden="true"></i> Preview
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() => editStream(stream, setDefaultStream, setStreamVisible)}
                  >
                    <i className="fa fa-pencil" aria-hidden="true"></i> Edit
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div className="carousel-container">
            <CarouselSlider images={stream.images} setViewCarousel={setViewCarousel}/>
          </div>
        </div>
      </div>
    );
  }
};
