import { StyleSheet } from "react-native";

export default StyleSheet.create({
 modalOverlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  dialog: {
    width: 832,
    // height: 214,
    backgroundColor: 'white',
    borderRadius: 8,
    padding: 40,
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    gap:24,
  },
  dialogHeading: {
    fontSize: 20,
    fontWeight: 'bold',
    alignSelf: 'flex-start',
  },
  label: {
    fontSize: 14,
    marginVertical: 5,
    marginBottom:0,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    width: '100%',
    paddingHorizontal: 10,
    borderRadius: 5,
  },
  button: {
    backgroundColor: '#37A0EA',
    padding: 10,
    borderRadius: 5,
    alignSelf: 'flex-end',
  },
  buttonText: {
    color: 'white',
  },
});